import React, { useEffect, useState } from "react";
import arrow from "../../assets/arrow.png";
import Blog from "./Blog";
import { blogs } from "../../data/data";

const Blogs = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [blogList, setBlogList] = useState(blogs[0]);
  const [tempBlogList, setTempBlogList] = useState([]);

  useEffect(() => {
    const list = [];
    blogs.map((item) => {
      item.map((blog) => {
        list.push(blog);
      });
    });
    setTempBlogList(list);
  }, [blogs]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 4);
  };

  const renderRadioInputs = () => {
    const radioInputs = [];
    for (let i = 0; i < 4; i++) {
      radioInputs.push(
        <input
          key={i}
          type="radio"
          name="slider"
          className={`slider__nav`}
          checked={i === currentSlide}
        />
      );
    }
    return radioInputs;
  };
  const addToList = () => {
    if(blogList.length < 12) {
      setBlogList([...blogList, tempBlogList[blogList.length]]);
    } else if(blogList.length === 12) {
      setBlogList(blogList.slice(0, 3));
    }
  };
  return (
    <section id="blog">
      <p data-aos="fade-up" data-aos-duration="1000" className="section__text__p1">Check out my</p>
      <h1 data-aos="fade-up" data-aos-duration="3000" className="title">Blogs</h1>

      <div className="container blog-list">
        <div className="row" data-aos="fade-up" data-aos-duration="3000">
          {blogList.map((blog) => (
            <div className="col-xs-12 g-5">
              <Blog
                key={blog.id}
                title={blog.title}
                link={blog.link}
                des={blog.description}
                image={blog.image}
                date={blog.date}
              />
            </div>
          ))}
        </div>

        <div class="load-more" data-aos="fade-up" data-aos-duration="3000">
          <button onClick={addToList}>{blogList.length === 12 ? "Hide":"Load More"}</button>
        </div>
      </div>

      <div class="slider" data-aos="fade-up" data-aos-duration="3000">
        <div className="blog-nav">{renderRadioInputs()}</div>

        <div class="slider__inner">
          {blogs.map((item, j) => (
            <div class="slider__contents">
              <div className="container">
                <div className="row g-5 mt-3 blog-row">
                  {item.map((blog, i) => (
                    <div className="col col-lg-4">
                      <Blog
                        key={blog.id}
                        id={i+j}
                        title={blog.title}
                        link={blog.link}
                        des={blog.description}
                        image={blog.image}
                        date={blog.date}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <img
        src={arrow}
        alt="Arrow icon"
        className="icon arrow"
        onclick="location.href='./#experience'"
      /> */}
    </section>
  );
};

export default Blogs;
