  import hello from "../assets/hello.png";
  import socialApp from "../assets/socialApp.png";
  import HRAMS from "../assets/HRAMS.png";
  import gym from "../assets/gym.png";
  import aiImage from "../assets/aiImage.png";
  import marana from "../assets/marana.png";
  import editImage from "../assets/editImage.png";







export const projects = [
  {
    title: "No Code/Low Code Web Application",
    des: "This is the product that I was part of during my internship period which is a web application that supports both administrative and client-side functionalities. This project aimed to provide a user-friendly interface for creating web applications with minimal coding requirements. I work as a full-stack developer on this.",
    image: "", 
    github: "",
    tech: ["ReactJS", "Antd", "Styled Components", "Redux", "Micro-frontend Architecture", "MongoDB", ".NET Core", "Microservices", "Docker", "Kubernetes", "Azure", "PostgreSql", "RabbitMQ"]
  },
  {
    title: "MERN Stack Social Media Platform with Microservices",
    des: "MERN Stack Social Media Platform with Microservices is a modern and scalable solution designed to provide users with a dynamic and engaging social networking experience. This project seamlessly integrates MongoDB, Express.js, React, and Node.js (MERN stack) along with microservices architecture to create a robust and efficient platform for sharing posts, liking, commenting, and managing user interactions.",
    image: socialApp,
    github: "https://github.com/kusham/Network-Mid-Exam-Project",
    tech: ["NodeJS", "ReactJS", "MongoDB", "ExpressJS", "Microservices", "Redux", "MaterialUI"]
  },
  {
    title: "Image Editing and Management web Platform",
    des: "Embark on a visual journey with our MERN Stack Image Editing and Management Platform, a versatile and user-friendly solution designed for uploading the images, editing, and managing images effortlessly. This project leverages the powerful combination of MongoDB, Express.js, React, and Node.js to provide a comprehensive set of image manipulation features, enabling users to transform their visuals with ease.",
    image: editImage,
    github: "https://github.com/kusham/Ai-Image-Generater",
    tech: ["NodeJS", "ReactJS", "MongoDB", "ExpressJS", "Tailwind CSS"]
  },
  {
    title: "Comprehensive Human Resource and Assets Management System (HRAMS)",
    des: "(HRAMS) is a cutting-edge web-based application designed to streamline company management tasks and empower employees with enhanced features. This system, developed with a Full-stack approach, incorporates Role-Based Access Control (RBAC) for secure user experiences. The MERN stack serves as the foundation, ensuring a robust and scalable architecture, while Material UI enhances the application's user-friendly design.",
    image: HRAMS,
    github: "https://github.com/kusham/B19_IN-2900---Industry-Based-Project-Frontend",
    tech: ["NodeJS", "ReactJS", "MongoDB", "ExpressJS", "MaterialUI"]
  },
  {
    title: "Friends and Fitness Sports Club Management System",
    des: "Our Friends and Fitness Sports Club Management System is a comprehensive web-based solution designed to streamline the interaction between club members, trainers, and administrators. The system provides an intuitive platform where visitors can register as members, access personalized services, and engage with fitness trainers seamlessly. Built on MongoDB, Express.js, ReactJS, and Node.js, the platform ensures a responsive and secure experience.",
    image: gym,
    github: "https://github.com/kusham/GYM-System",
    tech: ["NodeJS", "ReactJS", "SQL", "ExpressJS", "Redux", "Tailwind CSS"]
  },
  {
    title: "MEAN Stack Merchant and supplier Platform - Clothing Company",
    des: "The MEAN stack e-commerce project for a clothing company is a scalable platform facilitating seamless collaboration between merchants and suppliers to register and manage their services. Built on MongoDB, Express.js, ReactJS, Node.js, and @mantine UIs the platform ensures a responsive and secure experience. With features like efficient collaboration, real-time updates, and user-friendly interfaces, it optimizes operations within the clothing industry.",
    image: hello,
    github: "https://github.com/kusham/supplier-registration-hellodello",
    tech: ["NodeJS", "ReactJS", "MongoDB", "ExpressJS", "Mantine"]
  },
  {
    title: "Image Sharing Platform with AI-Generated Content",
    des: "Introducing our innovative project, the MERN Stack Community Image Sharing Platform, where creativity meets technology. This platform is designed to support the way users share and discover captivating images, and user friendly interface all powered by the cutting-edge capabilities of DALL-E, an AI image generation model. Harness the power of DALL-E, an advanced AI model by OpenAI, to dynamically generate unique and visually stunning images.",
    image: aiImage,
    github: "https://github.com/kusham/Ai-Image-Generater",
    tech: ["NodeJS", "ReactJS", "MongoDB", "ExpressJS", "Tailwind CSS", "OpenAI"]
  },
  {
    title: "Responsive Web Development from Adobe XD Designs",
    des: "Embark on a visually captivating journey with our project, where we transform Adobe XD designs into fully functional and responsive web pages. This seamlessly bridges the gap between design creativity and web development expertise, bringing digital experiences to life. Precision in translating Adobe XD designs into code, ensuring a pixel-perfect representation of the original vision, Attention to detail in layout, and visual elements for a rendition of the design.",
    image: marana,
    github: "https://github.com/kusham/Marana-website",
    tech: ["HTML", "CSS", "Javascript"]
  },
];
