import React, { useRef, useState } from "react";
import Typed from "typed.js";
import "./HeroStyles.css";
import linkedin from "../../assets/linkedin.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import github from "../../assets/github.png";
import kushan from "../../assets/kushan.png";
import cvPdf from "../../assets/Kushan-Madhusanka.pdf";

export const Hero = ({ setLoader }) => {
  const role = useRef(null);

  React.useEffect(() => {
    const typed = new Typed(role.current, {
      strings: [
        "Programmer.",
        "web Developer.",
        "mobile Developer.",
        "blogger.",
      ],
      typeSpeed: 80,
    });
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className="wrapper">
      <div
        data-aos="fade-right"
        data-aos-duration="3000"
        className="cols cols0"
      >
        <span className="topLine">Hello</span>
        <h1>
          I' m <span>Kushan Madhusanka</span>
        </h1>
        <h1 className="multiText">
          <span ref={role}></span>
        </h1>
        <p>
          A motivated, responsible undergraduate with the capacity to
          participate in tough pursuits, as well as the ability to work hard,
          collaborate, and think logically.
        </p>
        <div className="btns">
          <a
            class="btn btn-color-2"
            href={cvPdf}
            download="Kushan-Madhusanka-CV.pdf"
          >
            Download CV
            {/* <button class="btn btn-color-2"></button> */}
          </a>

          <a
            href="#contact"
            onClick={() => setLoader(true)}
            class="btn btn-color-1"
            onclick="location.href='#contact'"
          >
            Hire Me
          </a>
        </div>
        <div id="socials-container">
          <a href="https://www.linkedin.com/in/kushan-madhusanka/">
            <img
              src={linkedin}
              alt="My LinkedIn profile"
              class="icon"
              onclick="location.href='https://linkedin.com/'"
            />
          </a>
          <a href="https://github.com/kusham">
            <img
              src={github}
              alt="My Github profile"
              class="icon"
              onclick="location.href='https://github.com/'"
            />
          </a>
          <a href="https://fb.com/kushan.madhusanka.737">
            <img
              src={facebook}
              alt="My Github profile"
              class="icon"
              onclick="location.href='https://github.com/'"
            />
          </a>

          <a href="https://twitter.com/kushanmadhusan5">
            <img
              src={twitter}
              alt="My Github profile"
              class="icon"
              onclick="location.href='https://github.com/'"
            />
          </a>
          <a href="https://instagram.com/kush_an_rm">
            <img
              src={instagram}
              alt="My Github profile"
              class="icon"
              onclick="location.href='https://github.com/'"
            />
          </a>
        </div>
      </div>
      <div
        className="cols cols1"
        data-aos="fade-left"
        data-aos-duration="5000"
      >
        <div className="imgbox">
          <div className="blur"></div>
          <img src={kushan} />
        </div>
      </div>
    </div>
  );
};
