import React from "react";
import "./SkillStyles.css";
import SkillCard from "./SkillCard";
import html from "../../assets/html.png";
import css from "../../assets/css.png";
import js from "../../assets/js.png";
import react from "../../assets/react.png";
import node from "../../assets/node.png";
import net from "../../assets/.net.png";
import express from "../../assets/express.png";
import ts from "../../assets/ts.png";
import cSharp from "../../assets/cSharp.png";
import java from "../../assets/java.png";
import c from "../../assets/c.png";
import python from "../../assets/python.png";
import spring from "../../assets/spring.png";
import bootstrap from "../../assets/bootstrap.png";
import tailwind from "../../assets/tailwind.png";
import mongo from "../../assets/mongo.png";
import mysql from "../../assets/mysql.png";
import mssql from "../../assets/mssql.png";
import git from "../../assets/git.png";
import psql from "../../assets/psql.png";
import firebase from "../../assets/firebase.png";
import azure from "../../assets/azure.png";
import redis from "../../assets/redis.svg";
import k8s from "../../assets/kubernetes.png";
import graphql from "../../assets/graphql.png";

const skills = [
  [
    {
      image: html,
      name: "HTML",
    },
    {
      image: css,
      name: "CSS",
    },
    {
      image: js,
      name: "JavaScript",
    },
    {
      image: ts,
      name: "TypeScript",
    },
  ],
  [
    {
      image: react,
      name: "ReactJS",
    },
    {
      image: node,
      name: "NodeJS",
    },
    {
      image: express,
      name: "ExpressJS",
    },
    {
      image: net,
      name: ".NET",
    },
  ],
  [
    {
      image: java,
      name: "Java",
    },
    {
      image: python,
      name: "Python",
    },
    {
      image: cSharp,
      name: "C#",
    },
    {
      image: c,
      name: "C",
    },
  ],
  [
    {
      image: azure,
      name: "Azure",
    },
    {
      image: bootstrap,
      name: "Bootstrap",
    },
    {
      image: tailwind,
      name: "Tailwind",
    },
    {
      image: graphql,
      name: "GraphQL",
    },
  ],
  [
    {
      image: mysql,
      name: "MySQL",
    },
    {
      image: mssql,
      name: "MsSQL",
    },
    {
      image: mongo,
      name: "MongoDB",
    },
    {
      image: psql,
      name: "Postgresql",
    },
  ],
  [
    {
      image: spring,
      name: "SpringBoot",
    },
    {
      image: redis,
      name: "Redis",
    },
    {
      image: k8s,
      name: "K8s",
    },
    {
      image: git,
      name: "Git",
    },
  ],
];

const Skills = () => {
  return (
    <section id="skill">
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        className="section__text__p1"
      >
        Get to know my
      </p>
      <h1 data-aos="fade-up" data-aos-duration="3000" className="title">
        Skills
      </h1>
      <div class="skills-wrapper">
        {skills.map((item) => (
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className="skill-raw"
            key={item}
          >
            {item.map((skill) => (
              <SkillCard image={skill.image} name={skill.name} />
            ))}
          </div>
        ))}
      </div>

      {/* <img
        src={arrow}
        alt="Arrow icon"
        className="icon arrow"
        onclick="location.href='./#experience'"
      /> */}
    </section>
  );
};

export default Skills;
