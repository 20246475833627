import React, { useState } from "react";

import "./ExperienceStyles.css";
import aboutPic from "../../assets/kushan2.jpg";
import education from "../../assets/education.png";
import experience from "../../assets/experience.png";
import arrow from "../../assets/arrow.png";
import Education from "./Education";
import Work from "./Work";

export const Experience = () => {
  const [selected, setSelected] = useState(true);
  return (
    <section id="experience">
        <p data-aos="fade-up" data-aos-duration="1000" className="section__text__p1">Get To Know My</p>
        <h1 data-aos="fade-up" data-aos-duration="2000" className="title">Education & Experience</h1>


      <div className="experience-container">
        <div className="section__pic-container" data-aos="fade-right" data-aos-duration="1000">
          <img src={aboutPic} alt="Profile picture" className="about-pic" />
        </div>
        <div className="experience-details-container">
          <div className="experience-containers" data-aos="fade-up" data-aos-duration="2000">
            <div
              onClick={() => setSelected(true)}
              className={
                selected ? "details-container-selected" : "details-container"
              }
            >
              <img src={education} alt="Education icon" className="icon" />
              <h3>Education</h3>
            </div>
            <div
              onClick={() => setSelected(false)}
              className={
                !selected ? "details-container-selected" : "details-container"
              }
            >
              <img src={experience} alt="Experience icon" className="icon" />
              <h3>Experience</h3>
            </div>
          </div>
          <div className="text-container">
            {selected ? <Education /> : <Work />}
          </div>
        </div>
      </div>
      {/* <img
        src={arrow}
        alt="Arrow icon"
        class="icon arrow"
        onclick="location.href='./#experience'"
      /> */}
    </section>
  );
};
