import userReducer from "../assets/userReducer.png"
import osi from "../assets/osi.png"
import ai from "../assets/ai.png"
import solid from "../assets/solid.png"
import spa from "../assets/spa.png"
import oop from "../assets/oop.png"
import handleBar from "../assets/handleBar.png"
import api from "../assets/api.png"
import reactMistakes from "../assets/react-mistakes.png"
import redux from "../assets/redux.png"
import nodeBlog from "../assets/node-blog.png"






export const blogs = [
  [{
    id: 1,
    title: "What is useReducer in React?",
    link: "https://medium.com/javascript-in-plain-english/what-is-usereducer-in-react-9bb9824a6e30",
    description:
      "Can you manage the state without useState in React? Yesss…Do you have to always use useState to handle states. No..You don’t. Let…",
    image: userReducer,
    date: "Feb 19, 2023",
  },
  {
    id: 2,
    title: "OOP Concepts with Java",
    link: "https://medium.com/@kusham1998/oop-concepts-with-java-3c581e3ee75b",
    description:
      "OOP stands for Object Oriented Programming which is very important for programming. You will not be able to create systems using the…",
    image: oop,
    date: "Feb 4, 2023",
  },
  {
    id: 3,
    title: "SOLID Design Principles with JAVA",
    link: "https://medium.com/@kusham1998/solid-design-principles-with-java-d4b3b001164",
    description:
      "SOLID is simply an acronym for the first five object-oriented design principles by Robert C. Martin. Solid helps us to write better code…",
    image: solid,
    date: "Jan 19, 2023",
  },],
  [{
    id: 4,
    title: "What is OSI Modal | 7 Layers Explained",
    link: "https://medium.com/@kusham1998/7-layers-of-network-osi-model-b880fb47a7e5",
    description:
      "OSI stands for Open Systems Interconnect. This is a model that describes networking or telecommunications systems as seven…",
    image:osi,
    date: "Jan 17, 2023",
  },
  {
    id: 5,
    title: "History of Artificial Intelligence (AI) and How it has evolved",
    link: "https://medium.com/@kusham1998/history-of-artificial-intelligence-ai-and-how-it-has-evolved-3d7607e1fff8",
    description:
      "There will be no person who hasn't heard of the word AI. We are in the 4th industrial revolution and AI is the fuel for it. Through AI…",
    image: ai,
    date: "Jan 1, 2023",
  },
  {
    id: 6,
    title: "Redux for React developers-The complete guide",
    link: "https://medium.com/@kusham1998/redux-for-react-developers-the-complete-guide-a2aca79880d7",
    description:
      "Most react developers find redux as a complex and hard-to-learn concept. If I will be honest, for me also it was hard to grab in…",
    image: redux,
    date: "Sep 28, 2022",
  }],
  [{
    id: 7,
    title: "How to design an API-Best practices-Part 2",
    link: "https://medium.com/@kusham1998/how-to-design-an-api-best-practices-part-2-640a06efb3ba",
    description:
      "If you have already read part 1 of this article, you may know most of the basic things about APIs. If you have not, please read it and…",
    image: api,
    date: "Sep 2, 2022",
  },
  {
    id: 8,
    title: "What is an API — All the things you need to know-Part 1",
    link: "https://medium.com/@kusham1998/what-is-an-api-all-the-things-you-need-to-know-part-1-ba8b1753a174",
    description:
      "I am gonna talk about API which is an essential part when it comes to application development. If you are becoming…",
    image: api,
    date: "Aug 23, 2022",
  },
  {
    id: 9,
    title: "8 Mistakes every junior React developer makes",
    link: "https://medium.com/@kusham1998/8-mistakes-every-junior-react-developer-makes-7ceace725d36",
    description:
      "Every programmer who learns React makes a huge amount of mistakes in the learning process. Sometimes they don’t even know...",
    image: reactMistakes,
    date: "Aug 7, 2022",
  }],
  [{
    id: 10,
    title: "How to Render Static and Dynamic Files in Express with Handlebars",
    link: "https://medium.com/bitsrc/how-to-render-static-and-dynamic-files-in-express-with-handlebars-2046609e1c8f",
    description:
      "What is the difference between static and dynamic files. How to renderv static files. How to render dynamic files with hbs.",
    image: handleBar,
    date: "Jul 31, 2022",
  },
  {
    id: 11,
    title: "How to start a project with node.js and Express.js - For beginners",
    link: "https://medium.com/@kusham1998/how-to-start-a-project-with-node-js-and-express-js-for-beginners-95f0648c1224",
    description:
      "This article is completely for beginners. The questions mentioned below are the questions that you need to find out answers to before…",
    image: nodeBlog,
    date: " Jul 28, 2022",
  },
  {
    id: 12,
    title: "Use AngularJS Routing to build a simple single-page application",
    link: "https://medium.com/@kusham1998/use-angularjs-routing-to-build-a-simple-single-page-application-b8df1fbb0f62",
    description:
      "If you are a person who is interested in web development and especially you are a beginner, this article will suit you 100%…",
    image: spa,
    date: " Feb 23, 2021",
  }],
];
