import React from "react";
import "./FooterStyles.css";
import kushan3 from "../../assets/kushan3.png";
import linkedin from "../../assets/linkedin.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import github from "../../assets/github.png";
import arrow from "../../assets/arrow.png";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div
            className="footer-image"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <img src={kushan3} />
          </div>
          <h3 data-aos="fade-up" data-aos-duration="3000">
            Kushan Madhusanka
          </h3>
          <p data-aos="fade-up" data-aos-duration="3000">
            A motivated, responsible undergraduate with the capacity to
            participate in tough pursuits, as well as the ability to work hard,
            <span>collaborate, and think logically.</span>
          </p>
          <div
            className="social-links"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <a href="https://twitter.com/kushanmadhusan5" className="twitter">
              <img
                src={twitter}
                alt="My Github profile"
                onclick="location.href='https://twitter.com/kushanmadhusan5'"
              />
            </a>
            <a href="https://fb.com/kushan.madhusanka.737" className="facebook">
              <img
                src={facebook}
                alt="My Github profile"
                onclick="location.href='https://github.com/kusham'"
              />
            </a>
            <a href="https://instagram.com/kush_an_rm" className="instagram">
              <img
                src={instagram}
                alt="My Github profile"
                onclick="location.href='https://github.com/'"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/kushan-madhusanka/"
              className="linkedin"
            >
              <img
                src={linkedin}
                alt="My LinkedIn profile"
                onclick="location.href='https://linkedin.com/'"
              />
            </a>
            <a href="https://github.com/kusham" className="github">
              <img
                src={github}
                alt="My github profile"
                onclick="location.href='https://github.com/'"
              />
            </a>
          </div>
          <div className="wrapper-footer">
            <div
              className="divider"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <hr />
            </div>
          </div>
          <div className="credits" data-aos="fade-up" data-aos-duration="3000">
            <p>Copyright &#169; 2024. All Rights Reserved.</p>
            Designed by{" "}
            <a href="https://bootstrapmade.com/">Kushan Madhusanka</a>
          </div>
        </div>
        <div className="navigator" >
          <a href="#desktop-nav">
            <img
              src={arrow}
              alt="Arrow icon"
              class="arrow"
              onclick="location.href='./#experience'"
            />
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
