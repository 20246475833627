import React from "react";
import "./ProjectsStyles.css";
import githubImg from "../../assets/github.png";
import web from "../../assets/web.png";
import { Divider, Space, Tag } from "antd";
export const ProjectCard = ({ title, github, image, des, tech }) => {
  const savedTheme = localStorage.getItem("theme") || "light";
  return (
    <div className="project-card">
      <div className="project-entry">
        <img className="project-img" src={image} />
        <div className="project-container">
          <div className="project-title">
            <p>{title}</p>
          </div>
          <p className="project-des">{des}</p>
        </div>
        <div className="tech-tag">
          {tech?.map((tag) => (
            <Tag
              key={tag}
              style={{
                backgroundColor: savedTheme === "dark" ? "black" : "white",
                color: savedTheme === "dark" ? "white" : "purple",
                fontSize: "13px",
                height: "30px",
                lineHeight: "30px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
        <div className="project-button">
          <div className="button-img">
            <a href={github}>
              <img src={githubImg} />
            </a>
          </div>
          <div className="button-img">
            <img src={web} />
          </div>
        </div>
      </div>
    </div>
  );
};
