import React, { useEffect, useState } from "react";
import user from "../../assets/user.png";
import dateImage from "../../assets/date.png";

import "./BlogsStyles.css";

const Blog = ({ title, link, des, image, date , id}) => {

  const [imageHeight, setImageHeight] = useState(0);
  const x = id;
  useEffect(() => {
    // Function to adjust overlay height based on image height
    const adjustOverlayHeight = () => {
      const imageContainer = document.querySelector(`#image-blog`);
      const overlay = document.querySelector(`#overlay-blog`);
      const newImageHeight = imageContainer.clientHeight;

      // Update state with new image height
      setImageHeight(newImageHeight);

      // Adjust the overlay height based on the image height
      overlay.style.height = newImageHeight + 15 + 'px'; // Adjust the multiplier based on your requirements
    };

    // Initial adjustment when the component mounts
    adjustOverlayHeight();

    // Event listener for window resize
    window.addEventListener('resize', adjustOverlayHeight);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', adjustOverlayHeight);
    };
  }, [id]); 
  return (
    <div className="blog-card">
      <div className="blog-entry">
        <img src={image} id={`image-blog`}/>
        <div className={`overlay-blog`} id={`overlay-blog`}></div>
        <div className="">
          <div className="">
            <div className="blog-details">
              <div className="blog-date">
              <img src={dateImage} alt="" />
                <span>{date}</span>
              </div>
              <div className="blog-user">
                <img src={user} alt="" />
                <span className="">By Kushan Madhusanka</span>
              </div>
            </div>
          </div>
          <div className="blog-title">
            <a href={link}>{title}</a>
          </div>
          <p className="blog-des">{des}</p>
        </div>
      </div>
    </div>
  );
};

export default Blog;
