import React from "react";
import arrow from "../../assets/arrow.png";
import phone from "../../assets/phone.png";
import email from "../../assets/email.png";
import location from "../../assets/location.png";
import linkedin from "../../assets/linkedin.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import github from "../../assets/github.png";
import medium from "../../assets/medium.png";
import hackerrank from "../../assets/hackerrank.png";

import "./ContactStyles.css";

export const Contact = () => {
  return (
    <section id="contact">
      <p data-aos="fade-up" data-aos-duration="1000" className="section__text__p1">Connect with me</p>
      <h1 data-aos="fade-up" data-aos-duration="3000" className="title">Contacts</h1>

      <div className="contact-container" data-aos="fade-up">
        <div className="find-me" data-aos="fade-up" data-aos-duration="3000">
          <div className="find-me-item1">
            <h1>FIND ME IN</h1>
          </div>
          <div className="find-me-item2">
            <div className="social-links">
              <a href="https://twitter.com/kushanmadhusan5" className="twitter">
                <img
                  src={twitter}
                  alt="My Github profile"
                  onclick="location.href='https://github.com/'"
                />
              </a>
              <a href="https://fb.com/kushan.madhusanka.737" className="facebook">
                <img
                  src={facebook}
                  alt="My Github profile"
                  onclick="location.href='https://github.com/'"
                />
              </a>
              <a href="https://instagram.com/kush_an_rm" className="instagram">
                <img
                  src={instagram}
                  alt="My Github profile"
                  onclick="location.href='https://github.com/'"
                />
              </a>
              <a href="https://linkedin.com/in/https://www.linkedin.com/in/kushan-madhusanka/" className="linkedin">
                <img
                  src={linkedin}
                  alt="My LinkedIn profile"
                  onclick="location.href='https://linkedin.com/'"
                />
              </a>
              <a href="https://github.com/kusham" className="github">
                <img
                  src={github}
                  alt="My github profile"
                  onclick="location.href='https://github.com/'"
                />
              </a>
              <a href="https://www.hackerrank.com/@kusham1998" className="github">
                <img
                  src={hackerrank}
                  alt="My github profile"
                  onclick="location.href='https://github.com/'"
                />
              </a>
              <a href="https://medium.com/@kusham1998" >
                <img
                  src={medium}
                  onclick="location.href='https://github.com/'"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-details">
          <div className="details" data-aos="fade-left" data-aos-duration="3000">
            <div className="info">
              <div className="info-item">
                <img src={location} />
                <div>
                  <h4>Location:</h4>
                  <p>
                    541/2, Bodhinagala road, <br />
                    Dombagaskanda, Ingiriya.
                  </p>
                </div>
              </div>

              <div className="info-item">
                <img src={email} />
                <div>
                  <h4>Email:</h4>
                  <p> kushan.m.official@gmail.com</p>
                </div>
              </div>

              <div className="info-item">
                <img src={phone} />
                <div>
                  <h4>Call:</h4>
                  <p>+94 76 9 784 015</p>
                  <p>+94 75 8 462 809</p>
                </div>
              </div>
            </div>
          </div>

          <div className="map" data-aos="fade-right" data-aos-duration="3000">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1981.144127643299!2d80.14685708387684!3d6.734645307036649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1705787021583!5m2!1sen!2slk"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* <img
        src={arrow}
        alt="Arrow icon"
        className="icon arrow"
        onclick="location.href='./#experience'"
      /> */}
    </section>
  );
};
