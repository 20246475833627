import React from "react";
import "./ServiceStyles.css";
import "./icomoon.css";
import "./style.css";
// import "./bootstrap.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faServer,
  faComputer,
  faDatabase,
  faMobileScreen,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";

const Service = () => {
  return (
    <section id="services">
      <p
        data-aos="fade-up"
        data-aos-duration="3000"
        className="section__text__p1"
      >
        What I Do ?
      </p>
      <h1 data-aos="fade-up" data-aos-duration="3000" className="title">
        Here are Some of my Expertise
      </h1>
      <div className="container">
        <div className="row row-pt-md">
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-1">
              <span className="icon">
                <FontAwesomeIcon icon={faLightbulb} />
              </span>
              <div className="desc">
                <h3>Innovative Ideas</h3>
                <p>
                  Unleashing creativity to conceptualize and implement novel
                  solutions that stand out in the digital landscape.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-2">
              <span className="icon">
                <FontAwesomeIcon icon={faServer} />
              </span>
              <div className="desc">
                <h3>Backend Development</h3>
                <p>
                  Crafting robust and efficient server-side architectures,
                  ensuring seamless functionality and data processing.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-3">
              <span className="icon">
                <FontAwesomeIcon icon={faComputer} />
              </span>
              <div className="desc">
                <h3>Frontend Development</h3>
                <p>
                  Translating designs into captivating user interfaces, blending
                  aesthetics with intuitive and responsive web experiences.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-4">
              <span className="icon">
                <FontAwesomeIcon icon={faPenNib} />
              </span>
              <div className="desc">
                <h3>Graphic Design</h3>
                <p>
                  Transforming concepts into visually striking designs and
                  communication for impactful visual storytelling.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-5">
              <span className="icon">
                <FontAwesomeIcon icon={faDatabase} />
              </span>
              <div className="desc">
                <h3>Database Design</h3>
                <p>
                  Architecting scalable and optimized database structures,
                  enhancing data management for efficient systems.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="services color-6">
              <span className="icon">
                <FontAwesomeIcon icon={faMobileScreen} />
              </span>
              <div className="desc">
                <h3>Mobile Applications</h3>
                <p>
                  Developing cutting-edge mobile applications, delivering
                  seamless user experiences across diverse platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
