import React, { useEffect, useState } from "react";
import "./NavbarStyle.css";
import linkedin from "../../assets/linkedin.png";
import moon from "../../assets/moon.png";
import sun from "../../assets/sun.png";
import github from "../../assets/github.png";
import instagram from "../../assets/instagram.png";
export const Navbar = ({ setLoader, toggleTheme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [updateThemeIcon, setUpdateThemeIcon] = useState("light");
  useEffect(() => {
    setUpdateThemeIcon(localStorage.getItem("theme") || "light");
  }, [updateThemeIcon]);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <nav id="desktop-nav">
        <div className="logo">
          KM<b>.</b>
        </div>
        <div className="nav-links-wrapper">
          <ul className="nav-links">
            <li>
              <a href="#services" onClick={() => setLoader(true)}>
                Services
              </a>
            </li>
            <li>
              <a href="#experience" onClick={() => setLoader(true)}>
                Resume
              </a>
            </li>
            <li>
              <a href="#skill" onClick={() => setLoader(true)}>
                Skills
              </a>
            </li>
            <li>
              <a href="#project" onClick={() => setLoader(true)}>
                Projects
              </a>
            </li>
            <li>
              <a href="#blog" onClick={() => setLoader(true)}>
                Blogs
              </a>
            </li>
            <li>
              <a href="#contact" onClick={() => setLoader(true)}>
                Contact
              </a>
            </li>
          </ul>
          <div className="nav-social">
            <a href="https://www.linkedin.com/in/kushan-madhusanka/">
              <img
                src={linkedin}
                alt="My LinkedIn profile"
                class="icon"
                onclick="location.href='https://linkedin.com/'"
              />
            </a>
            <a href="https://github.com/kusham">
              <img
                src={github}
                alt="My Github profile"
                class="icon"
                onclick="location.href='https://github.com/'"
              />
            </a>
            <a href="https://instagram.com/kush_an_rm">
              <img
                src={instagram}
                alt="My Github profile"
                class="icon"
                onclick="location.href='https://github.com/'"
              />
            </a>
            <a
              onClick={() => {
                toggleTheme();
                setUpdateThemeIcon(updateThemeIcon == "dark" ? "light" : "dark");
              }}
            >
              <img
                src={updateThemeIcon == "dark" ? sun : moon}
                alt="My Github profile"
                className="icon jump"
                onclick="location.href='https://github.com/'"
              />
            </a>
          </div>
        </div>
      </nav>

      <nav id="hamburger-nav">
        <div className="logo">
          KM<b>.</b>
        </div>
        <div className="hamburger-menu">
        <a
              onClick={() => {
                toggleTheme();
                setUpdateThemeIcon(updateThemeIcon == "dark" ? "light" : "dark");
              }}
            >
              <img
                src={updateThemeIcon == "dark" ? sun : moon}
                alt="My Github profile"
                className="icon jump"
                onclick="location.href='https://github.com/'"
              />
            </a>
          <div className="hamburger-icon" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={`menu-links ${menuOpen ? "open" : ""}`}>
            <li>
              <a
                href="#services"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#experience"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Resume
              </a>
            </li>
            <li>
              <a
                href="#skill"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Skills
              </a>
            </li>
            <li>
              <a
                href="#projects"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                href="#blog"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Blogs
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => {
                  setLoader(true);
                  toggleMenu();
                }}
              >
                Contact
              </a>
            </li>
          </div>
        </div>
      </nav>
    </>
  );
};
