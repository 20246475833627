import React from "react";

const Education = () => {
  return (
    <section className="col">
      <div className="contents">
        <div className="box" data-aos="fade-left" data-aos-duration="1000">
          <h4>B.Sc.(Hons.) in Information Technology</h4>
          <h3>2020 - 2024</h3>
          <h6>University of Moratuwa, Sri Lanka</h6>
          <ul>
            <li>
              Holds a Bachelor's in Information Technology from the University
              of Moratuwa.
            </li>
            <li>
              Specialized in Algorithms, Data structures, Database Management,
              Networking, Software Development, and Project Management.
            </li>
            <li>Engaged in hands-on projects for practical experience.</li>
            <li>Received mentorship to enhance skills.</li>
            <li>
              Built a strong foundation for a successful career in technology.
            </li>
          </ul>
        </div>

        <div className="box" data-aos="fade-left" data-aos-duration="3000">
          <h4>G.C.E Advanced Level - Science Streamy</h4>
          <h3>2016 - 2018</h3>
          <h6>Taxila Central college, Horana</h6>
          <ul>
            <li>
              Successfully completed Advanced Level (AL) examinations in the
              Biology stream.
            </li>
            <li>
              Achieved notable grades ABB in the challenging subjects within the
              Biology stream.
            </li>
            <li>
              Demonstrated proficiency and dedication in logical thinking.
            </li>
          </ul>
          <p></p>
        </div>

        <div className="box" data-aos="fade-left" data-aos-duration="3000">
          <h4>G.C.E Ordinary Level</h4>
          <h3>2009 - 2014</h3>
          <h6>Gamini Central college, Ingiriya</h6>
          <ul>
            <li>
              Achieved outstanding results in Ordinary Level (OL) examinations,
              securing 8As & C in the Sinhala medium.
            </li>
            <li>
              Attained a solid academic foundation and developed a strong work
              ethic.
            </li>

            <li>
              Cultivated a passion for continuous learning during my time at
              Gamini Central college.
            </li>
            <li>
              Demonstrated commitment to academic excellence throughout my
              school years.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Education;
