import React from "react";

import "./ProjectsStyles.css";

import arrow from "../../assets/arrow.png"
import { ProjectCard } from "./ProjectCard";

import {projects} from "../../data/projects"


export const Projects = () => {
  return (
    <section id="project">
      <p data-aos="fade-up" data-aos-duration="3000" className="section__text__p1">Browse my recent</p>
      <h1 data-aos="fade-up" data-aos-duration="3000" className="title">Projects</h1>

      <div className="container">
        <div className="row g-5 mt-3 justify-content-center" >
        {
          projects.map(project=> (
            <div key={project} className="col col-lg-4" data-aos="fade-up" data-aos-duration="3000">
            <ProjectCard title={project.title} des={project.des} image={project.image} github={project.github} tech={project.tech}/>
          </div>
          ))
        }
        </div>
      </div>

      {/* <img
        src={arrow}
        alt="Arrow icon"
        className="icon arrow"
        onclick="location.href='./#experience'"
      /> */}
    </section>
  );
};
