import { Hero } from "./components/Hero/Hero";
import { Navbar } from "./components/Navbar/Navbar";
import "./App.css";
import { Experience } from "./components/Experience/Experience";
import Footer from "./components/Footer/Footer";
import { Projects } from "./components/Projects/Projects";
import { useEffect, useState } from "react";
import Blogs from "./components/Blogs/Blogs";
import { Contact } from "./components/Contact/Contact";
import Skills from "./components/Skills/Skills";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Service from "./components/Services/Service";

function App() {
  const [loader, setLoader] = useState(true);

  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    document.documentElement.setAttribute('data-theme', savedTheme);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoader(false);
    }, 1000); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, [loader]);
  return (
    <>
      {loader && <div id="preloader"></div>}
      <Navbar setLoader={setLoader} toggleTheme = {toggleTheme}/>
      <Hero setLoader={setLoader}/>
      <Service />
      <Experience />
      <Skills />
      <Projects />
      <Blogs />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
