import React from 'react'
import "./SkillStyles.css"

const SkillCard = ({image, name}) => {
  return (
    <div className='skill-card'>
        <img src={image} />
        <div className="name-skill">{name}</div>
    </div>
  )
}

export default SkillCard