import React from "react";

const Work = () => {
  return (
    <section className="col">
      <div className="contents">
        <div className="box" data-aos="fade-left" data-aos-duration="1000">
          <h4>Software Engineering Intern</h4>
          <h3>January 2023 - September 2023</h3>
          <h6>Millennium IT ESP Pvt Ltd, Sri Lanka</h6>
          <ul>
            <li>
              Full-Stack Developer specialized in creating efficient
              No-code/Low-code platform.
            </li>
            <li>
              Proficient in technologies like ReactJS, Microfrontend
              Architecture, .NET, Microservices, Docker, Kubernetes (K8s),
              MongoDB, PostgreSQL, Azur DevOps, and Git.
            </li>
            <li>
              Familiarity with various Integrated Development Environments
              (IDEs) including VS Code, Visual Studio, pgAdmin 4 and Docker
              Desktop.
            </li>
            <li>
              Proven track record of contributing to successful projects and
              delivering high-quality software solutions.
            </li>
          </ul>
        </div>

        <div className="box" data-aos="fade-left" data-aos-duration="3000">
          <h4>Content Writer on Medium Platform</h4>
          <h3>2021 - Present</h3>
          <h6>Medium</h6>
          <ul>
            <li>
              Passionate content writer on Medium, contributing valuable
              insights in technology and programming.
            </li>
            <li>
              Demonstrates commitment to continuous learning and sharing
              knowledge through engaging and informative content.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Work;
